import React, { useState, useEffect, useRef } from 'react';
import { appConfig } from './constansts';


const winProducts = appConfig.products;

const WheelOfFortune = () => {
	const [isInitiiated, setIsInitiated] = useState(false);
	const [isSpinning, setIsSpinning] = useState(false);
	
	const [sectors, setSectors] = useState(null);
	const [rolledSector, setRolledSector] = useState(null); // [sectorIndex, sectorLabel
	
	const [isFinished, setIsFinished] = useState(false); // [sectorIndex, sectorLabel

	const [playId, setPlayId] = useState(null); // [sectorIndex, sectorLabel

 	const wheelRef = useRef(null);

  function play() {
	// set isSpinning to true
	setIsSpinning(true);

	// set animation to running
	wheelRef.current.style.animationPlayState = 'running';


	// call get-win.php via POST request
	fetch(`${appConfig.server}/get-win.php`, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: null
	})
		.then(response => response.json())
		.then(data => {
			console.log('data', data);

					setIsFinished(true);

			// get current rotation of the wheel
			const currentRotation = wheelRef.current.style.transform;
			
			
			const randomSector = sectors[data.winning_sector.index];
			// target random sector in rand
			// slice a circle into equal parts
			// find targetRotation for the random sector
			// the sectors go counter clockwise
			const targetRotationStart = sectors.indexOf(randomSector) / sectors.length * 2 * Math.PI;
			const targetRotationEnd = targetRotationStart + 2 * Math.PI / sectors.length;

			// targetRotation is random between targetRotationStart and targetRotationEnd
			const targetRotation = targetRotationStart + Math.random() * (targetRotationEnd - targetRotationStart);






			const revs = rand(5, 6);

			const finalTargetRotation = (targetRotation + revs * 2 * Math.PI);

			const OneRotationTimeMs = 1200;

			// calculate required time for the wheel to rotate to the target sector
			// take the current rotation into account
			let timeMs = OneRotationTimeMs * (finalTargetRotation - currentRotation) / (2 * Math.PI) ;
			// make timeMs alwas positive
			if (timeMs < 0) {
				timeMs *= -1;
			}


			console.log('currentRotation', currentRotation, finalTargetRotation, revs, timeMs, targetRotation);


			wheelRef.current.animate(
				[
					{ transform: currentRotation },
						{ transform: `rotate(${finalTargetRotation}rad)` }
				],
				{
					duration: timeMs,
					iterations: 1,
					easing: 'cubic-bezier(.44,.54,.59,1)',
					fill: 'forwards'
				})
				.onfinish = () => {
					setIsSpinning(false);
					setRolledSector(data.winning_sector);
				};
		})
		.catch(error => {
			console.error('error', error);

			setIsSpinning(false);
		})
		.finally(() => {
		});
	
		
  }

	useEffect(() => {
		fetchGameIdAndSectors();
	}, []);


	function resetGame() {
		
		const currentRotation = wheelRef.current.style.transform;
		wheelRef.current.animate(
			[
				{ transform: currentRotation },
					{ transform: `rotate(0rad)` }
			],
			{
				duration: 30,
				iterations: 1,
				easing: 'cubic-bezier(.44,.54,.59,1)',
				fill: 'forwards'
			})

		setIsFinished(false);
		setRolledSector(null);
		setIsInitiated(false);
		setSectors(null);

		// reset transform

		fetchGameIdAndSectors();
	}

	function fetchGameIdAndSectors() {
		fetch(`${appConfig.server}/get-game.php`)
			.then(response => response.json())
			.then(data => {
				setIsInitiated(true);
				setSectors(data.sectors);
				setPlayId(data.play_id);
			})
			.catch(error => {
				alert('Derzeit ist keine Verbindung zum Server möglich. Bitte versuchen Sie es später noch einmal.');
			});
	}

  return (
    <div className='con600'>
		<div style={{
			textAlign: 'center',
		}}>
			<img src={`${appConfig.client}/down.png`} alt='wheel' style={{
				width: '100px',
				height: 'auto',
				display: 'inline-block',
				position: 'relative',
				top: '30px',
				zIndex: '10',
				marginTop: '-30px',
			}} />
		</div>
		<div className='relative'>
      <div id='wheel' key={
		playId
	  } className={isSpinning && !isFinished ? 'rotate' : ''} 
	  ref={wheelRef} 
	  onClick={isSpinning || !isInitiiated ? null : isFinished && rolledSector ? resetGame : play} 
	  style={{
		backgroundImage: `url(${appConfig.client}/scheibe.png)`,
		backgroundSize: 'contain',
		backgroundRepeat: 'no-repeat',
		borderRadius: '100%',

	  }}></div>

	  <div className='over-wheel'>
		{rolledSector && isFinished ? (<div>

			{rolledSector.win ? (<div style={{}}>
				<h1>Glückwunsch, du hast gewonnen!</h1>
				<div className='win-container'>
					<div className='win-product'>
						{winProducts[rolledSector.product]}
					</div>
				</div>

					Dein persönlicher Code lautet: 
					<input type='text' value={rolledSector.coupon_code} readOnly style={{
						border: 'none',
						width: '100%',
						padding: '10px',
						background: 'none',
						fontSize: '20px',
						fontFamily: 'monospace',
						display: 'block',
						margin: '10px auto',
					}}
						onClick={(e) => {
							e.target.select();
							document.execCommand('copy');
						}} />
			</div>) : (<div>
				<h1>Schade, du hast nicht gewonnen!</h1>
			</div>)}

		</div>) : (<span>
			Dreh am Pizza-Roller-Glücksrad und wage dein Glück, um Freigetränke und Rabatte zu gewinnen!
		</span>)}
	  <div style={{
		textAlign: 'center',
	  }}>
		<button 
			className={`button-play ${isSpinning || !isInitiiated ? 'disabled' : ''}`}
			onClick={isFinished && rolledSector ? resetGame : play} 
			disabled={isSpinning || !isInitiiated} 
			style={{
			}}>
				<a className='play-button-text'>{!isInitiiated ? "Lade Spiel ..." : isSpinning ? "Drehen ..." : isFinished && rolledSector ? "Nochmal Spielen" : "Drehen"}</a>
		</button>
	  </div>
			<div className='text-law'>
				Code nur in Verbindung mit einer Bestellung bei Pizza-Roller Eppingen einlösbar. 
				Nur 1 Code pro Bestellung, nicht mit anderen Aktionen kombinierbar. 
				Gewinncode gültig 10 Tage nach erspielen ({(() => {
					const date = new Date();
					date.setDate(date.getDate() + 10);
					return date.toLocaleDateString('de-DE');
				})()}). Nur bei Abholung.
			</div>
			<hr className='divider'/>
			<div className='powered-by'>
				<span>
					Powered by
				</span>
				<a href="https://www.eppingen.org/" target="_blank">
					<img src={`${appConfig.client}/org.png`} alt='eppingen' style={{
						width: '32px',
						height: 'auto',
						display: 'block',
					}} />

					
				</a>
				<span>&</span>
				<a href="https://www.pizza-roller.org/" target="_blank" rel="noopener noreferrer">
					<img src={`${appConfig.client}/pizza-roller-icon.png`} alt='eppingen' style={{
						width: '32px',
						height: 'auto',
						display: 'block',
					}} />

					
				</a>
			</div>
	
		</div>

		</div>
	  {sectors && sectors.map((sector, index) => (
		  <div key={index} hidden style={{	
		  }}>
			  {sector.label}
			</div>
	  ))}
    </div>
  );


  function rand(from, to) {
	return Math.floor(Math.random() * (to - from + 1)) + from;
  }
};

export default WheelOfFortune;
