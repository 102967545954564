import './App.css';
import WheelOfFortune from './WheelOfFortune';



function App() {
  return (
    <div className="wheel-of-fortune-app">
        <WheelOfFortune />
    </div>
  );
}

export default App;
