

export const appConfig = {
	client: window.pit_wheelOfFortune_localAppPath ||  '',
	server: window.pit_wheelOfFortune_remoteAppPath || '',
	products: {
		"10percent": "10% Rabatt auf deine nächste Bestellung",
		"5percent": "5% Rabatt auf deine nächste Bestellung",
		"getraenk": "Freigetränk zu deiner Bestellung",
	}
	
}

